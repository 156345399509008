import { graphql } from "gatsby"
import React from "react"
import PageSlider from "../components/ACF/Slider"
import Home from "../components/Layouts/home"
import SEO from "../components/seo"

class Index extends React.Component {
  render() {
    let {
      location,
      data: {
        wpPage: {
          title,
          seo,
          acf_home_carousel: { linkedProjects: carousel },
        },
      },
    } = this.props

    // console.log(seo)

    return (
      <Home location={location} title={title}>
        <SEO bodyClasses="home" seo={seo} />
        <section id="content">
          <div className="row no-gutters">
            <div className="col-md-12">
              <PageSlider data={carousel} cssClass="home-carousel" />
            </div>
          </div>
        </section>
      </Home>
    )
  }
}

export default Index

export const homeQuery = graphql`
  {
    wpPage(isFrontPage: { eq: true }) {
      id
      title
      seo {
        ...WpYoastSEO
      }
      acf_home_carousel {
        linkedProjects {
          __typename
          ... on WpProject {
            id
            title
            excerpt
            uri
            featuredImage {
              node {
                ...fragmentImage
              }
            }
          }
        }
      }
    }
  }
`
