import parse from "html-react-parser"
import React, { Component } from "react"
import Slider from "react-slick"
import { MenuContext } from "../../context/MenuContext"
import Image from "../image"

export default class PageSlider extends Component {
  static contextType = MenuContext
  render() {
    const { isMenuOpen } = this.context
    // console.log(isMenuOpen)

    const { data, cssClass } = this.props

    var settings = {
      dots: true,
      arrows: true,
      fade: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: true,
      autoplaySpeed: 7000,
      speed: 2000,
      swipeToSlide: true,
      pauseOnHover: false,
      afterChange: (current, next) => {
        if (typeof window !== `undefined`) {
          let el = document.getElementsByTagName("body")[0]
          if (current === 3 || current === 4) {
            el.classList.add("dark")
          } else {
            el.classList.remove("dark")
          }
        }
      },
      // beforeChange: (current, next) => console.log(next)
    }

    // console.log(data);
    return (
      <div className={cssClass} id="page-slider">
        <Slider slickGoTo="0" {...settings}>
          {data &&
            data.map((slide, index) => {
              // let content = slide.text
              // console.log(slide)
              return (
                <div key={index}>
                  <div className="slide">
                    {slide?.featuredImage && (
                      <Image data={slide.featuredImage} />
                    )}

                    <div className={`caption ${isMenuOpen ? "d-none" : ""}`}>
                      <h1 className="link">{parse(slide.excerpt)}</h1>
                    </div>
                  </div>
                </div>
              )
            })}
        </Slider>
      </div>
    )
  }
}
