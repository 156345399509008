import React from "react"
import MenuContextProvider from "../../context/MenuContext"
import Header from "../Header"

const Home = ({ children, location }) => {
  return (
    <MenuContextProvider>
      <main>
        <div className="container-fluid p-0">
          <div className="row no-gutters">
            <div className="col-12">
              <Header location={location} />
              {children}
            </div>
          </div>
        </div>
      </main>
    </MenuContextProvider>
  )
}

export default Home
